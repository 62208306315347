import { IGetClientResModule } from '@msg91/models/root-models';
import { createSelector } from '@ngrx/store';
import { IAppState } from '../app.state';
import { IRootState } from '../reducer/root.reducer';

export const selectState = (state: IAppState) => state;

export const selectRootState = createSelector(selectState, (p) => p.root);

export const selectRootErrors = createSelector(selectRootState, (rootState: IRootState) => rootState.errors);
export const selectTitle = createSelector(selectRootState, (rootState: IRootState) => rootState.headerTitle);

export const selectClients = createSelector(selectRootState, (rootState: IRootState) => rootState.clients);
export const selectClientInProcess = createSelector(
    selectRootState,
    (rootState: IRootState) => rootState.clientsInProcess
);
export const selectClientSuccess = createSelector(selectRootState, (rootState: IRootState) => rootState.clientsSuccess);

export const selectSelectedClient = createSelector(
    selectRootState,
    (rootState: IRootState) => rootState.selectedClient
);
export const selectSelectedClientCurrency = createSelector(selectSelectedClient, (r: IGetClientResModule) => {
    if (r && r.user_currency) {
        return r.user_currency;
    }
    return null;
});
export const selectSelectedClientIsWalletUser = createSelector(selectSelectedClient, (r: IGetClientResModule) => {
    if (r && r.userWalletEnabled) {
        return +r.userWalletEnabled;
    }
    return null;
});
export const selectSelectedClientUserPid = createSelector(selectSelectedClient, (r: IGetClientResModule) => {
    if (r && r.user_pid) {
        return r.user_pid;
    }
    return null;
});
export const selectShowClientList = createSelector(
    selectRootState,
    (rootState: IRootState) => rootState.showClientList
);
export const selectSideBarToggleStatus = createSelector(
    selectRootState,
    (rootState: IRootState) => rootState.sideBarToggleStatus
);

export const selectInboundEmailLogPermission = createSelector(
    selectRootState,
    (rootState: IRootState) => rootState.inboundEmailLogPermission
);

export const selectEditUserPermission = createSelector(
    selectRootState,
    (rootState: IRootState) => rootState.editUserPermission
);

export const selectAccessToken = createSelector(selectRootState, (rootState: IRootState) => rootState?.token);
